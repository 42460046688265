import { ActionTypes } from '..';

export const setTotalAssets = ({ count }: { count: number }) => ({
  type: ActionTypes.SET_TOTAL_ASSETS,
  payload: count,
});

export const loadSvgStart = ({ id }: { id: string }) => ({
  type: ActionTypes.LOAD_SVG_START,
  payload: id,
});

export const loadSvgComplete = ({ id }: { id: string }) => ({
  type: ActionTypes.LOAD_SVG_COMPLETE,
  payload: id,
});
